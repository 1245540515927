import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initFacebookSdk } from './utils/facebookSDK';
import { Auth0Provider } from "@auth0/auth0-react";

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
        domain="dev-o-i4uaqn.us.auth0.com"
        clientId="XweTzhi4xsUVtPh6RnVuIXZjKLng63fb"
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>,
    </React.StrictMode>,
    document.getElementById('root')
  );
}

initFacebookSdk().then(startApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
