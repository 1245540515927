import axios from 'axios';

export const refreshSubscriptions = async () => {
    try {
        const res = await axios.get(`https://crb-n8n.internal.reachout.global/webhook/8edbaf65-e80f-4cec-8490-6887d7f01c43`);

        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const listPages = async (user_id, token) => {
    try {
        const res = await axios.get(`https://crb-n8n.internal.reachout.global/webhook/2267b531-a96a-4b68-bb93-4dc19862b096?user_id=${user_id}&token=${token}`);

        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const extendToken = async (token) => {
    try {
        const res = await axios.get(`https://crb-n8n.internal.reachout.global/webhook/extend-token-fb?token=${token}`);

        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}