import setAuthorizationToken from '../utils/setAuthorizationToken';

let initialState = {};

if (localStorage.AUTH) {
  initialState = JSON.parse(localStorage.getItem('AUTH'));
  setAuthorizationToken(initialState.token);
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state = {
        isAuthenticated: true,
        token: action.token,
        user: action.payload
      }
      localStorage.setItem('AUTH', JSON.stringify(state));
      return state;
    
    case 'USER_DATA':
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      }
      localStorage.setItem('AUTH', JSON.stringify(state));
      return state;
    
    case 'USER_IMAGE':
      state = {
        ...state,
        user: {
          ...state.user,
          profile_pic: [action.image]
        },
      }
      localStorage.setItem('AUTH', JSON.stringify(state));
      return state;
      
    case 'LOGOUT':
      state = {
        isAuthenticated: false,
        user: {},
        token: false,
      };
      localStorage.removeItem('AUTH');
      return state;

    default:
      return state;
  };
};

export default authentication;