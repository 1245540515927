const facebookAppId = process.env.REACT_APP_FB_APP_ID;


export function initFacebookSdk() {
    console.log('init!');
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            console.log('async');
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v11.0',
            });

            // auto authenticate with the api if already logged in with facebook
            window.FB.getLoginStatus((r) => {
                console.log('facebook', r);
                if (r.status === 'connected') {
                    //connectFacebook(r.authResponse);
                }
                resolve(r);
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}