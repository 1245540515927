import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Register = (props) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="authincation h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={loginWithRedirect}>Sign In</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;