import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux'
/* STORE */
import Store from './reducers';
/* SCREENS */
import Register from './screens/Register';
import FacebookLogin from './screens/FacebookLogin';

const App = () => {
  return (
    <Provider store={Store}>
      <Router>
        <Route path='/' component={FacebookLogin} />
        <Route path='/register' component={Register} /> 
      </Router>
    </Provider>
  );
}

export default App;
