import authReducer from './auth';
import loadingReducer from './loading';
import {combineReducers, createStore} from 'redux';

const rootReducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
});

const store = createStore(rootReducers);

export default store;