import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {extendToken, listPages, refreshSubscriptions} from '../services/auth';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const Facebook = () => {
	const [loading, setLoading] = useState(true);
	const [id, setId] = useState(null);
	const [token, setToken] = useState(null);
	const [pages, setPages] = useState([]);
	const { logout } = useAuth0();

	useEffect(() => {
		window.FB.getLoginStatus((r) => {
			setLoading(false);
			if (r.status === 'connected') {
				setId(r.authResponse.userID);
				setLoading(true);
				setToken(r.authResponse.accessToken);
			}
		}, {scope: 'pages_show_list read_page_mailboxes ads_management ads_read leads_retrieval pages_read_engagement pages_manage_metadata pages_manage_ads'});
	}, []);

	useEffect(() => {
		const getPages = async () => {
			const a = await listPages(id, token);
			setPages(a.data);
			setLoading(false);
		}

		if (id && token) {
			getPages();
			// setLoading(false);
		}
	}, [id, token]);

    const loginwithfb = async () => {
        window.FB.login((r) => {
			if (r.status === 'connected') {
				setId(r.authResponse.userID);
				setLoading(true);
				setToken(r.authResponse.accessToken);
			}
		}, {scope: 'pages_show_list read_page_mailboxes ads_management ads_read leads_retrieval pages_read_engagement pages_manage_metadata pages_manage_ads'});
    };

	const fbLogout = async () => {
		setLoading(true);
        window.FB.logout((r) => {
			console.log(r);
			setId(null);
			setLoading(false);
			setToken(null);
		});
    };

	const copyPageId = async (id) => {
		navigator.clipboard.writeText(id);
		toast("Page ID copied to clipboard");
	};

	const extend = async (token) => {
		setLoading(true);
		const res = await extendToken(token);
		navigator.clipboard.writeText(res.access_token);
		setLoading(false);
		toast("Token copied to clipboard");
	};

	const refresh = async () => {
		await refreshSubscriptions();
		toast("Subscriptions Refreshed");
	};

    return (
			<div className="container">
				<ToastContainer />
				<div style={{
					position: 'fixed',
					bottom: 25,
					right: 25,
					zIndex: 9999,
					display: 'flex',
					flexDirection: 'column',
				}}>
					<button className="btn btn-warning light btn-xs mb-3" onClick={refresh}>Refresh Subscriptions</button>
					<a href="https://docs.google.com/spreadsheets/d/1XcoqdOPH2svSBlcSpKrtAGn3PCX4xr2co0x_dIxyPQI/edit#gid=0" target="_blank" rel="noreferrer" className="btn btn-success light btn-xs text-success mb-3">Open Spreadsheet</a>
					<button className="btn btn-warning light btn-xs mb-3" onClick={fbLogout}>Disconnect from Facebook</button>
					<button className="btn btn-danger light btn-xs" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
				</div>
				{
					loading && (
						<div style={{
							position: 'fixed',
							zIndex: 999,
							height: '100%',
							width: '100%',
							top: 0,
							left: 0,
							background: '#FFFFFFC6',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}><div className="spinner-border text-primary" role="status">
						<span className="sr-only">Loading...</span>
					  </div></div>
					)
				}
				{
					!id && (
						<div className="row">
							<div className="text-center col-12 my-5">
								<h3>Connect your Facebook Account</h3>
								<button className="btn btn-lg bg-primary text-white" onClick={loginwithfb}>Connect with Facebook</button>
							</div>
						</div>
					)
				}
				{ id && token && (
					<div className="row">
						<div className="col-12 mt-5">
							<h3>Your pages</h3>
							<hr />
						</div>
					</div>
				)}
				<div className="row">
				{
					id && token && pages && pages.map(page => (
						<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3" key={page.id}>
							<div className="card h-auto">
								<div className="card-body text-center">
									<img src={page.picture.data.url} width={page.picture.data.url} className="rounded-circle mb-4" alt=""/>
									<h4 className="mb-3 text-black font-w600">{ page.name }</h4>
									<input type="text" className='form-control text-center mb-3' value={page.id} readOnly onClick={() => copyPageId(page.id)} />
									<button className="btn btn-primary light btn-xs" onClick={() => extend(page.access_token)}>Copy Token</button>
								</div>
							</div>
						</div>
					))
				}
				</div>
            </div>
    );
};

export default withAuthenticationRequired(Facebook);